import React, { Component } from "react";
import "./demo.css";

class Demo extends Component {
  render() {
    return (
      <div>
        {process.env.REACT_APP_IS_DEMO === "true" && (
          <div className="demo">DEMO</div>
        )}
      </div>
    );
  }
}

export default Demo;
