import React, {Component} from "react";
import SignatureCanvas from "react-signature-canvas";
import Demo from "./demo/demo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCircleExclamation, faSpinner,} from "@fortawesome/free-solid-svg-icons";
import Error from "./error/error";
import "./app.css";

const API_URL = process.env.REACT_APP_BASE_API_URL + "/signature/";

const getData = (url = "") => {
    return fetch(url).then((response) => {
        if (response.ok) {
            return response.json();
        }
        return Promise.reject(response);
    });
};

const postData = (url = "", data = {}, contentType) => {
    let headers = {};
    if (contentType === "json") {
        headers = {
            "Content-Type": "application/json",
        };
    }

    // Default options are marked with *
    return fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: headers,
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: data, // body data type must match "Content-Type" header
    }).then((response) => {
        if (response.ok) {
            return response.json(); // parses JSON response into native JavaScript objects
        }
        return Promise.reject(response);
    });
};

function getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i += 1) {
        const pair = vars[i].split("=");
        if (pair[0] === variable) {
            return pair[1];
        }
    }
    return false;
}

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trimmedDataURL: null,
            isSubmitted: false,
            signatureCode: getQueryVariable("id"),
            customerName: "",
            error: "",
            orderError: "",
            orderID: "",
            signPath: "",
            isDone: false,
        };
        getData(`${API_URL}${this.state.signatureCode}`)
            .then((data) => console.log(data))
            .catch((error) => this.setState({orderError: error.toString()}));
    }

    sigPad = {};

    clear = () => {
        this.sigPad.clear();
    };

    handleChange = (event) => {
        this.setState({customerName: event.target.value});
    };

    trim = () => {
        window.t = this;
        if (!this.state.customerName || this.sigPad.isEmpty()) {
            this.setState({error: "Please provide your name and your signature!"});
            return;
        }
        this.setState({isSubmitted: true});
        const canvas = this.sigPad.getCanvas();
        canvas.toBlob((blob) => {
            const img_fd = new window.FormData();
            img_fd.append("signature", blob, "signature.png");

            const fd = new window.FormData();
            fd.append("customer_name", this.state.customerName);
            fd.append("signature", blob, "signature.png");

            postData(`${API_URL}${this.state.signatureCode}`, fd)
                .then((data) => {
                    if (data.error) {
                        this.setState({error: data.error});
                        console.log(this.state.error);
                    } else {
                        this.setState({
                            error: "",
                        });
                        this.setState({
                            signPath: data.image_url,
                        });
                    }
                    this.setState({isSubmitted: false});
                })
                .catch((error) => {
                    this.setState({isSubmitted: false});
                    this.setState({
                        error:
                            error.error || "An error has occurred. Please, try again later.",
                    });
                });
        });
    };

    render() {
        const {trimmedDataURL} = this.state;
        return (
            <div className="App">
                <Demo></Demo>
                <header className="App-header">
                    <img
                        src="assets/images/logo_dark.svg"
                        alt="logo"
                        className="logo"
                    ></img>
                    {this.state.orderError && (
                        <Error error_message={this.state.orderError}/>
                    )}
                    {!this.state.signPath && (
                        <h3>Please Sign here to confirm delivery {this.sigPad.isEmpty}</h3>
                    )}
                    {this.state.signPath && (
                        <div className="thanks">
                            <div>
                                <FontAwesomeIcon icon={faCheck} size="5x"/>
                            </div>
                            Thank you for confirming delivery
                        </div>
                    )}
                    {this.state.error !== "" && (
                        <div className="sigContainer">
                            <div className="alert alert-danger">
                                <FontAwesomeIcon icon={faCircleExclamation}/>
                                <i className="vSpacer"></i>
                                {this.state.error}
                            </div>
                        </div>
                    )}
                    {!this.state.signPath && (
                        <div className="sigContainer">
                            <input
                                name="name"
                                placeholder="Your Name"
                                className="name-input"
                                value={this.state.customerName}
                                onChange={this.handleChange}
                            ></input>
                            <SignatureCanvas
                                penColor="green"
                                canvasProps={{
                                    className: "sigCanvas",
                                    // maxWidth: 720,
                                    // minWidth: 300,
                                }}
                                ref={(ref) => {
                                    this.sigPad = ref;
                                }}
                            />
                            <button onClick={this.clear} className="btn btn-outlined">
                                Reset
                            </button>
                            {!this.state.isSubmitted && (
                                <button onClick={this.trim} className="btn submit-button">
                                    Submit
                                </button>
                            )}
                            {this.state.isSubmitted && (
                                <button className="btn">
                                    <FontAwesomeIcon icon={faSpinner} spin/>
                                </button>
                            )}
                        </div>
                    )}

                    {trimmedDataURL ? (
                        <img className="" alt="signature" src={trimmedDataURL}/>
                    ) : null}
                </header>
            </div>
        );
    }
}

export default App;
